<template>
  <div id="app">
    <router-view :key="$route.path" class="view"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
@import "~bootstrap-icons/font/bootstrap-icons.css";
</style>

<style lang="css">
:root {
  --sidebar-bg-colour: #ffffff;
  --sidebar-item-hover: #dce8f6;
  --sidebar-item-active: #013d82;
  --light: #f1f5f9;
  --white: #ffffff;
  --primary: #0e80ec;
  --sidebar-width: 250px;
  /* --color-primary: #2d8ee3; */
  --color-primary: #094890;
  --color-primary-light: #d9e6f5;
  --color-darkgreen: #183b1c;
  --color-muted: #6c757d;
  --color-darkgrey: #3c3c3c;
  --color-softblack: #1d1d1f;
}

.color-primary {
  color: var(--color-primary) !important;
}

.color-muted {
  color: var(--color-muted) !important;
}

.color-darkgrey {
  color: var(--color-darkgrey) !important;
}

html,
body,
#app,
.view {
  width: 100%;
  height: 100%;
}

#app > .view {
  overflow-x: hidden;
}

.text-primary {
  color: var(--color-primary) !important;
}

.text-white {
  color: #fff !important;
}

.text-softblack {
  color: #1d1d1f !important;
}

.btn {
  height: 38px;
}

.btn-primary {
  border: 1px solid #094890 !important;
  background-color: #094890 !important;
  color: #ffffff;
  border-radius: 6px;
}

.btn-primary:hover {
  background-color: #0e80ec !important;
  color: #ffffff;
}

.btn-primary-outlined {
  border: 1px solid #094890 !important;
  background-color: #ffffff !important;
  border-radius: 6px !important;
  color: #094890 !important;
}

.bg-blue {
  border: 1px solid none !important;
  background: #094890 !important;
  color: #ffffff !important;
  border-radius: 6px;
}

.btn-primary-outlined:hover {
  background-color: #094890 !important;

  color: #ffffff !important;
}

.btn-grey-outlined {
  border: 1px solid #bcbdbc !important;
  background-color: #ffffff !important;
  color: #888888 !important;
}

.btn-grey-outlined:hover {
  background-color: #efefef !important;
  color: #888888 !important;
}

.btn-grey-outlined.active {
  border: 1px solid #bcbdbc !important;
  background-color: #bcbdbc !important;
  color: #4a4a4a !important;
}

.btn-warning-outlined {
  border: 1px solid #f08000 !important;
  background-color: #ffffff !important;
  color: #f08000 !important;
}

.btn-warning-outlined:hover {
  background-color: #f9efe5 !important;
  color: #f08000 !important;
}

.btn-warning-outlined.active {
  border: 1px solid #bcbdbc !important;
  background-color: #bcbdbc !important;
  color: #f08000 !important;
}

.btn-arrow-icon {
  height: 38px;
  width: 38px;
  display: grid;
  place-items: center;
}

.br-r {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left: 0 !important;
}

.br-l {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
  border-right: 0 !important;
}

.br-0 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.btn-red {
  border: 1px solid #d21617 !important;
  background-color: #d21617 !important;
  color: #ffffff;
  border-radius: 6px;
}

.btn-red:hover {
  border: 1px solid #ac1313 !important;
  background-color: #ac1313 !important;
  color: #ffffff;
  border-radius: 6px;
}

.app-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* sidebar always expand option */
  /* margin-left: 5rem; */
  /*  */
}

#app {
  font-family: "Inter", "Roboto", "Arial", "san-serif";
  /* font-family: "Roboto", Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background: #ffffff;
  display: flex;
}

@media only screen and (max-width: 768px) {
  /* ::-webkit-scrollbar {
    display: none !important;
  } */
  p,
  span,
  small,
  b,
  a,
  li {
    line-height: 1.5rem;
  }
}

#app ::selection {
  /* Change highlight background color to black */
  background: #60c0f7;
  /* Change highlight text color to red */
  color: white;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: transparent;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2c8de0;
  border-radius: 5px;
  border-left: 3px solid white;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  cursor: pointer;
}

.modal::-webkit-scrollbar-thumb {
  background: #2c8de0;
  border-radius: 5px;
  border-left: 3px solid #7f7f7f;
  border-top: 3px solid #7f7f7f;
  border-bottom: 3px solid #7f7f7f;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #479bea;
  border-left: 5px solid #479bea;
  border-top: 5px solid #479bea;
  border-bottom: 5px solid #479bea;
}

@media screen and (max-width: 650px) {
  .row {
    line-height: 60%;
  }
}

@media screen and (max-width: 575px) {
  .row {
    line-height: 50%;
  }
}

button {
  cursor: pointer;
  appearance: none;
  border: transparent;
  outline: none;
  background: none;
}

.table {
  margin: 0px !important;
}

tr:last-child td {
  border-bottom-width: 0px;
}

.td-none {
  text-decoration: none;
  color: inherit;
}

.text-primary {
  color: var(--color-primary) !important;
}

.text-darkgrey {
  color: var(--color-darkgrey) !important;
}

.text-darkgreen {
  color: #183b1c;
}

.tooltip.b-tooltip-primary .tooltip-inner {
  background-color: #094890 !important;
}

p,
span,
th,
td,
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Roboto" ,system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; */
  /* font-family: "Open Sans"; */
  font-family: "Inter", "Roboto", "Arial", "san-serif";
}

.nexcard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
  padding: 2rem;
}

.vue-country-select .dropdown-list {
  overflow-x: hidden !important;
  width: 420px !important;
}

.input-border {
  border: 1px solid rgb(164, 164, 164);
}

/* date picker style */
.b-form-datepicker .dropdown-menu {
  background-color: #dce8f6;
}

.b-form-datepicker .btn svg {
  fill: #2c8de0;
}

.b-calendar-grid {
  box-shadow: none !important;
  border: 1px solid transparent !important;
  padding: 0px 5px !important;
}

.b-calendar-grid-weekdays .col {
  font-size: 12px !important;
}

.b-form-datepicker .btn:hover {
  background-color: #c8c8c8;
}

.b-form-btn-label-control.form-control > .btn:hover {
  background-color: #fff;
}

/* sidebar right popover style */

.sidebar-right__popover {
  border: 1px solid transparent !important;
  background-color: #dce8f6 !important;
  translate: 0;
}

.sidebar-right__popover.activity {
  translate: 0px;
}

.sidebar-right__popover .popover-body {
  padding: 0.6rem 1rem 0.4rem !important;
  color: #094890 !important;
  font-weight: 650;
  height: 42px;
}

@media only screen and (max-width: 991px) {
  .sidebar-right__popover {
    display: none !important;
  }
}

/* custom popover */
.custom__popover {
  border: 1px solid transparent !important;
  background-color: #dce8f6 !important;
  translate: 10px 0px;
}

.custom__popover .popover-body {
  padding: 0.6rem 1rem 0.4rem !important;
  color: #6c6c6c !important;
  font-weight: 600;
  height: 42px;
}

/* route link css */
.route__linkpath p {
  display: flex;
  align-items: center;
}

.route__linkpath .btn-back {
  margin-left: -0.3rem;
  margin-right: 0.3rem;
  font-size: 26px;
  cursor: pointer;
  /* border: 2px solid transparent; */
  padding: 0.1rem;
  border-radius: 50%;
  transition: 0.4s ease-out;
  color: var(--color-primary);
}

.route__linkpath .btn-back:hover {
  /* border: 2px solid rgba(216, 219, 223, 0.75); */
  background-color: #e5e5e5;
  transition: 0.4s ease-out;
}

.route__link {
  text-decoration: none !important;
  color: #6c757d;
}

.route__link:hover {
  text-decoration: none !important;
  color: #094890 !important;
  text-decoration: underline;
  transition: all 0.5s ease-out;
}

/* user select none */
.us-0 {
  user-select: none;
}

/* font weight 600 */
.fw-600 {
  font-weight: 600;
}

/* card inputs */
.card__inputbody {
  margin: 0 auto;
  padding: 2rem 0rem;
  max-width: 900px;
  display: flex;
  flex-direction: column;
}

.modal-card__title {
  color: #094890;
  font-weight: bold;
  margin: 1rem 0rem;
}

.card_inputgroup {
  display: flex;
  align-items: center;
  color: #094890;
  margin: 1rem 0;
}

.card_inputgroup__title {
  min-width: 200px;
  font-weight: bold;
}

.card_inputgroup__input {
  width: 100%;
}

.card_inputgroup__title .material-icons-outlined {
  font-size: 15px;
  cursor: default;
}

.card_inputgroup__radio {
  display: flex;
  justify-content: start;
  margin-right: -1rem;
  user-select: none;
}

.card_inputgroup__radio .form-check .form-check-input {
  visibility: hidden;
}

.card_inputgroup__radio .form-check .form-check-label {
  transform: translate(-24px, 0px);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background-color: var(--white);
  border-radius: 20px;
  padding: 0.2rem 0.8rem;
  cursor: pointer;
}

.card_inputgroup__radio .form-check .form-check-label:hover {
  background-color: var(--light);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  transition: all 0.3s ease-out;
}

.card_inputgroup__radio
  .form-check
  .form-check-input:checked
  ~ .form-check-label {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--white);
  transition: all 0.3s ease-out;
}

@media only screen and (max-width: 991px) {
  .page-wrapper-xl {
    height: calc(100vh - 68px);
    padding: 1.5rem !important;
    overflow-x: auto !important;
  }

  .page-wrapper-full {
    height: calc(100vh - 68px);
    padding: 1rem !important;
    overflow-x: auto !important;
  }

  .page-wrapper-md {
    padding: 1.5rem !important;
  }

  .mw-900-lg {
    min-width: 900px;
  }

  /* .route__linkpath p {
    flex-direction: row;
    flex-wrap: wrap;
  } */
}

/* testing temp */
.page-wrapper-full {
  position: relative;
  margin: 0rem auto;
  padding: 1rem;
}

.page-wrapper-xl {
  position: relative;
  max-width: 1600px;
  margin: 0rem auto;
  padding: 2rem 3rem 3rem;
}

.page-wrapper-lg {
  position: relative;
  max-width: 1200px;
  margin: 0rem auto;
  padding: 2rem 3rem 3rem;
}

.page-wrapper-md {
  position: relative;
  max-width: 1000px;
  margin: 0rem auto;
  padding: 2rem 2rem 3rem;
}

/* TODO: set page wrapper classes */
/* .page-wrapper-lg {
  position: relative;
  max-width: 1200px;
  margin: 0rem auto;
  padding: 2rem 3rem 3rem;
} */

/* additional width classes */
.w-5 {
  width: 5% !important;
}

.w-7-5 {
  width: 7.5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-120px {
  width: 120px !important;
}

.w-150px {
  width: 150px !important;
}

.h-50 {
  height: 50% !important;
}

.h-80 {
  height: 80% !important;
}

.h-100 {
  height: 100% !important;
}

.h-103 {
  height: 103% !important;
}

.h-110 {
  height: 110% !important;
}

.line-height-120 {
  line-height: 120%;
}

.line-height-150 {
  line-height: 150%;
}

.line-height-200 {
  line-height: 200%;
}

.cursor-pointer {
  cursor: pointer !important;
}

/* modal radio group */
.b-form-radio-buttons {
  display: flex;
}

.b-form-radio-buttons .btn-group > .btn {
  flex: none;
}

.b-form-radio-buttons input {
  display: none;
}

.b-form-radio-buttons .btn-primary {
  border: 1px solid var(--color-primary) !important;
  color: var(--color-primary) !important;
  background-color: var(--white) !important;
  border-radius: 20px !important;
  padding: 0.2rem 0.8rem !important;
  cursor: pointer !important;
  user-select: none !important;
}

.b-form-radio-buttons .btn-primary:not(:first-child) {
  margin-left: 0.5rem !important;
}

.b-form-radio-buttons .btn-primary:hover {
  background-color: var(--light);
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  transition: all 0.3s ease-out;
}

.b-form-radio-buttons .btn-primary.active {
  background-color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
  color: var(--white) !important;
  transition: all 0.3s ease-out !important;
}

/* screenshot */
.screenshot_web__img-wrapper {
  object-fit: cover;
  border: 1px solid #9a9a9a;
  background-color: #e8e8e8;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.screenshot_web__img-wrapper .expandable-image img {
  border-radius: 10px;
  max-height: 350px !important;
}

/*  */
.nextable td span img {
  max-width: 150px;
  object-fit: contain;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.table td span img {
  max-width: 150px;
  object-fit: contain;
  display: block;

  margin-left: auto;
  margin-right: auto;
}

.h-38px {
  height: 38px !important;
}

.border-blue {
  border: 1px solid #094890 !important;
  /* height: 38px !important; */
}

/* input css */
.search.input-group {
  padding-bottom: 0px;
  height: 38px !important;
}

.search.input-group .input-group-text {
  padding-right: 0rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;

  border-right: 0;
  height: 38px;
}

/* .input-group .input-group-text .material-icons-outlined{
  color: #094890;
} */
.search.input-group input {
  border-left: 0;
}

.search.input-group input:focus {
  border-color: #ced4da;
  border-left: 0;
  box-shadow: none;
}

.search.input-group:focus-within {
  border-radius: 0.375rem;
}

/* Content Editor Tooltips */
.ql-undo {
  font-size: 1.1rem;
  color: #343a40;
}

.ql-star {
  font-size: 1.5rem;
  color: #ff8000;
}

.ql-copy {
  font-size: 1.1rem;
  color: #343a40;
}

.ql-size:hover::after {
  content: "Font Size";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  font-weight: 400;
}

.ql-font:hover::after {
  content: "Font";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  font-weight: 400;
}

.ql-header:hover::after {
  content: "Formatting";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  font-weight: 400;
}

.ql-bold:hover::after {
  content: "Bold";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-italic:hover::after {
  content: "Italic";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-underline:hover::after {
  content: "Underline";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-strike:hover::after {
  content: "Strikethrough";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-color:hover::after {
  content: "Text Color";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-background:hover::after {
  content: "Text Highlight";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-list[value="ordered"]:hover::after {
  content: "Ordered List";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-list[value="bullet"]:hover::after {
  content: "Bullet List";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-indent[value="+1"]:hover::after {
  content: "Indent";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-indent[value="-1"]:hover::after {
  content: "Indent Reverse";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-align.ql-icon-picker .ql-picker-label[aria-expanded="false"]:hover::after {
  content: "Text Align";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-align .ql-picker-item:first-child:hover::after {
  content: "Text Align Left";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-align .ql-picker-item:nth-child(2):hover::after {
  content: "Text Align Center";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-align .ql-picker-item:nth-child(3):hover::after {
  content: "Text Align Right";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-align .ql-picker-item:nth-child(4):hover::after {
  content: "Text Align Justify";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-link:hover::after {
  content: "Add Hyperlink";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-image:hover::after {
  content: "Add Image";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-video:hover::after {
  content: "Add Video";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-code-block:hover::after {
  content: "Code Block";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-clean:hover::after {
  content: "Remove Styling";
  color: white;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
  background-color: #094890;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 400;
}

.ql-editor b {
  font-size: 15px !important;
}

.ql-editor p {
  font-size: 15px !important;
}

.ql-editor h3 {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.ql-editor h2 {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.ql-editor h1 {
  font-size: 26px !important;
  font-weight: 600 !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.k-progress-outer {
  padding-right: 0 !important;
}

/* nexcard blue */
.nexcard-blue {
  border: 1px solid #094890;
  padding: 1rem;
  border-radius: 10px;
}

/* Tabs - Folder Tabs */
.tabs__folderTabs {
  border-bottom: 2px solid #c4c4c4;
}

.folderTab {
  padding: 0.3rem 0.5rem;
  transition: 0.2s ease-out;
  border-bottom: 2px solid transparent;
  translate: 0px 2px;
}

.folderTab:hover {
  color: #094890;
  border-bottom: 2px solid #094890;
  transition: 0.4s ease-out;
}

.folderTab.active {
  background-color: #eeeeee;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom: 2px solid #094890;
  transition: all 0.4s ease-out;
}

.folderTab.active .folderTab__title {
  color: #094890 !important;
  transition: all 0.4s ease-out;
}

.folderTab .folderTab__title {
  color: grey;
  font-weight: 600;
  font-size: 15px;
  transition: all 0.4s ease-out;
  user-select: none;
}

/* Tabs - Pills Tabs */
.tabs__pillTabs {
  display: flex;
  margin-bottom: 1rem;
}

.pillTab {
  display: flex;
  align-items: center;
  padding-top: 0.2rem;
  padding-bottom: 0.1rem;
  margin-right: 0.4rem;
  transition: 0.2s ease-out;
  border-radius: 20px;
  border: 1px solid #094890;
  background-color: #f4faff;
}

.pillTab:hover {
  background-color: #dcefff;
  transition: 0.4s ease-out;
}

.pillTab.active {
  background-color: #094890;
  transition: all 0.4s ease-out;
}

.pillTab .pillTab__title {
  padding: 0rem 0.5rem;
  color: #094890;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.4s ease-out;
  user-select: none;
}

.pillTab.active .pillTab__title {
  color: #fff;
  transition: all 0.4s ease-out;
}

.br-rounded {
  border-radius: 50% !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-center {
  margin: 0 auto;
}

.font-weight-bold {
  font-weight: 600;
}

.display-block {
  display: block;
}

.icon-blue {
  color: #094890;
}

.text-black {
  color: #000000;
}

.text-blue {
  color: #094890 !important;
}

.text-blue-underline {
  color: #094890;
  text-decoration: underline;
  display: inline;
  cursor: pointer;
}

/* @media screen and (max-width: 1024px) {
  .table-card {
    overflow-x: auto;
  }
  .table {
    width: 1000px !important;
  }

  tbody {
    max-height: 500px !important;
    
  }

  .general_container {
    margin: 0rem !important;
  }
} */

@media screen and (max-width: 960px) {
  .table-card {
    overflow-x: auto;
  }

  .table {
    width: 1000px !important;
  }

  tbody {
    max-height: 500px !important;
  }
}

@media screen and (max-width: 576px) {
  .table {
    width: 1000px !important;
  }

  tbody {
    max-height: 800px !important;
  }
}

.popover {
  /* transform: translate3d(834px, 113px, 0px) !important; */
  will-change: auto !important;
}

.m0 {
  margin-bottom: 0;
}

.line-spacing {
  line-height: 1.1;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-24 {
  font-size: 24px;
}

.block {
  display: block;
}

.contents-display {
  display: contents;
}

.count_length {
  translate: -10px -25px;
}

.credit__box {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0.5rem;
  width: 100%;
  border: 2px solid #094890;
  border-radius: 10px;
  user-select: none;
}

/* orbitter */

.orbit-spinner,
.orbit-spinner * {
  box-sizing: border-box;
}

.orbit-spinner {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  perspective: 800px;
}

.orbit-spinner .orbit {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.orbit-spinner .orbit:nth-child(1) {
  left: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-one-animation 1200ms linear infinite;
  border-bottom: 3px solid #094890;
}

.orbit-spinner .orbit:nth-child(2) {
  right: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-two-animation 1200ms linear infinite;
  border-right: 3px solid #094890;
}

.orbit-spinner .orbit:nth-child(3) {
  right: 0%;
  bottom: 0%;
  animation: orbit-spinner-orbit-three-animation 1200ms linear infinite;
  border-top: 3px solid #094890;
}

@keyframes orbit-spinner-orbit-one-animation {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes orbit-spinner-orbit-two-animation {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes orbit-spinner-orbit-three-animation {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

/* ... */

.circles-to-rhombuses-spinner,
.circles-to-rhombuses-spinner * {
  box-sizing: border-box;
}

.circles-to-rhombuses-spinner {
  height: 15px;
  width: calc((15px + 15px * 1.125) * 3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.circles-to-rhombuses-spinner .circle {
  height: 15px;
  width: 15px;
  margin-left: calc(15px * 1.125);
  transform: rotate(45deg);
  border-radius: 10%;
  border: 3px solid #000;
  overflow: hidden;
  background: transparent;

  animation: circles-to-rhombuses-animation 1200ms linear infinite;
}

.circles-to-rhombuses-spinner .circle:nth-child(1) {
  animation-delay: calc(150ms * 1);
  margin-left: 0;
}

.circles-to-rhombuses-spinner .circle:nth-child(2) {
  animation-delay: calc(150ms * 2);
}

.circles-to-rhombuses-spinner .circle:nth-child(3) {
  animation-delay: calc(150ms * 3);
}

.custom-control-label {
  margin-left: 10px !important;
}

@media only screen and (max-width: 320px) {
  .custom-control-label {
    margin-left: 6px !important;
  }
}

/* pagination */

.page-link.active,
.active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color) !important;
  background-color: #094890 !important;
  border-color: #094890 !important;
}

.page-link {
  color: #094890 !important;
}

@keyframes circles-to-rhombuses-animation {
  0% {
    border-radius: 10%;
  }

  17.5% {
    border-radius: 10%;
  }

  50% {
    border-radius: 100%;
  }

  93.5% {
    border-radius: 10%;
  }

  100% {
    border-radius: 10%;
  }
}

@keyframes circles-to-rhombuses-background-animation {
  50% {
    opacity: 0.4;
  }
}

/* .custom-dropdown .btn-secondary {
    --bs-btn-color: none  !important;
    --bs-btn-bg: none  !important;
    --bs-btn-border-color: #dee2e6  !important;
    --bs-btn-hover-color: none  !important;
    --bs-btn-hover-bg: none  !important;
    --bs-btn-hover-border-color: none  !important;
    --bs-btn-focus-shadow-rgb: none  !important;
    --bs-btn-active-color: none  !important;
    --bs-btn-active-bg: none  !important;
    --bs-btn-active-border-color: none  !important;
    --bs-btn-active-shadow: blue  !important;
    --bs-btn-disabled-color: none  !important;
    --bs-btn-disabled-bg: none  !important;
    --bs-btn-disabled-border-color: none  !important;
}

.custom-dropdown .btn-secondary:hover {
  outline: none;
} */

.new-styles .vs__dropdown-toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--vs-search-input-bg);
  border: 1px solid #dee2e6;
  border-radius: 5px;
  display: flex;
  padding: 0 0 4px;
  white-space: normal;
  --vs-line-height: 1.62;
}

.new-styles .vs__clear {
  display: none;
}

.new-styles .vs__open-indicator {
  fill: #6f7377;
}

.h-20 {
  max-height: 200px !important;
  overflow: auto;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 1rem; */
}

.multiselect,
.multiselect__input,
.multiselect__single {
  min-height: 20px !important;
  font-size: 14px !important;
}

/* .multiselect__tags {
  min-height: 20px;
} */

.multiselect__placeholder {
  color: #706e6e !important;
}

.multiselect__option--highlight {
  background: #094890 !important;
}

.multiselect__tag {
  background: #094890 !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a !important;
}

.multiselect__option--selected {
  background: #41b883 !important;
  color: #ffffff !important;
  font-weight: 700;
}

.multiselect__tag-icon:after {
  font-size: 14px;
  color: #ffffff !important;
}

.multiselect__tag-icon:hover {
  background: #0c5fbe !important;
  color: #ffffff !important;
}

.reportrange-text {
  border: 1px solid #dee2e6 !important;
}

.vue-daterange-picker {
  z-index: 9 !important;
}

.vue-daterange-picker .form-control {
  line-height: 1.9 !important;
}

.vue-daterange-picker .daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #094890 !important;
  border-color: transparent;
  color: #fff;
}

#clientAddLabel .multiselect {
  width: 117% !important;
}

#clientAddLabel .multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 16px !important;
}

#clientAddLabel .multiselect__placeholder {
  color: #706e6e !important;
  font-size: 16px !important;
}

#clientEditLabel .multiselect {
  width: 110% !important;
}

#clientPill .pill-container {
  display: flex;
  flex-wrap: wrap;
}

#clientPill .pill {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  margin: 0.2rem;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #094890;
  border-radius: 10rem;
}

#caseLawPill .pill {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  margin: 0.2rem;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #00c441;
  background-color: #ffffff;
  border-radius: 10rem;
}

#medCaseLawPill .pill {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  margin: 0.2rem;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #ffae00;
  background-color: #ffffff;
  border-radius: 10rem;
}

#lowCaseLawPill .pill {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  margin: 0.2rem;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  color: #fc3c0d;
  background-color: #ffffff;
  border-radius: 10rem;
}

#searchCaseLaw .input-group-text {
  color: #ffffff !important;
  background-color: #094890 !important;
  border: 1px solid #094890 !important;
}

#customAgreementGenerator .input-group-text {
  background-color: #094890 !important;
  border: var(--bs-border-width) solid #094890 !important;
  border-radius: var(--bs-border-radius);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#customAgreementGenerator .bg-primary {
  --bs-bg-opacity: 1;
  background-color: #094890 !important;
}

.vue-slider-dot-tooltip-inner {
  border-color: #094890 !important;
  background-color: #094890 !important;
}

.input-tel__input[data-v-e59be3b4] {
  cursor: pointer;
  background-color: #fff;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  position: relative;
  width: 100.1% !important;
  padding: 0 12px;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid #dee2e6 !important;
  font-size: 16px !important;
  font-family: "Inter", "Roboto", "Arial", "san-serif" !important;
  z-index: 0;
  margin-left: -1px;
  height: 40px;
  min-height: 40px;
}

.country-selector__input[data-v-46e105de] {
  cursor: pointer;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 40px;
  min-height: 40px;
  padding-right: 22px;
  font-weight: 400;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: 1px solid #dee2e6 !important;
  font-size: 16px !important;
  font-family: "Inter", "Roboto", "Arial", "san-serif" !important;
  z-index: 0;
  padding-left: 8px;
  color: #000;
}

.country-selector__toggle[data-v-46e105de] {
  position: absolute;
  right: 5px;
  top: calc(50% - 14px) !important;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  text-align: center;
  display: inline-block;
  cursor: pointer;
  height: 24px;
}

.vue-phone-number-input .select-country-container[data-v-19351537] {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 120px;
  flex: 0 0 140px !important;
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important;
}

.input-tel__label[data-v-e59be3b4] {
  position: absolute;
  top: 2px !important;
  cursor: pointer;
  left: 11px !important;
  -webkit-transform: translateY(25%);
  transform: translateY(25%);
  opacity: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 11px;
  color: #747474;
}

.country-selector__label[data-v-46e105de] {
  position: absolute;
  top: 2px !important;
  cursor: pointer;
  left: 10px !important;
  -webkit-transform: translateY(25%);
  transform: translateY(25%);
  opacity: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 11px;
  color: #747474;
}
/* .modal-lg,
.modal-xl {
  --bs-modal-width: 990px;
} */

#statesFilter .multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: transparent !important;
  color: #a6a6a6;
}

.title-sizing {
  font-weight: bold !important;
}

#activity-sidebar .b-sidebar-header {
  margin-left: 12px;
  display: flex;
  flex-direction: row-reverse;
}
#activity-sidebar .b-sidebar-header button {
  margin-right: 0px;
  margin-left: auto;
}

#longLine {
  width: 60% !important;
}

.form-control::file-selector-button {
  background-color: #9f9fa09a !important;
  border: 1px solid #9f9fa09a !important;
  border-radius: 4px 0 0 4px !important;
  cursor: pointer;
}
</style>
