import axios from "axios";
// change API here



var baseURL = ''


if (location.href.includes('www.beta.nexlaw.ai')) {
    baseURL = "https://app.nexmind.nexodn.com/api/";
}
else if (location.href.includes('www.platform.nexlaw.ai')) {
    baseURL = "https://api.platform.nexmind.ai/api/"
}
else {
    baseURL = "https://app.nexmind.nexodn.com/api/";
}


//



export default axios.create({
    baseURL

});