import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // =============================================================================
    //Layout without navigation bars
    // =============================================================================
    {
      path: "/",
      component: () => import("@/components/FullLayoutPage.vue"),
      children: [
        {
          path: "/",
          redirect: "/login",
        },

        // =============================================================================
        //Authentication pages
        // =============================================================================

        //No page (Here is signUp in Background.vue it's stated as signIn)
        {
          path: "/signup",
          name: "SignUp",
          component: () => import("@/Pages/AuthenticationPages/Background.vue"),
          props: true,
        },

        //Login.vue
        {
          path: "/login",
          name: "SignIn",
          component: () => import("@/Pages/AuthenticationPages/Background.vue"),
          props: true,
        },

        //EnterEmail.vue
        {
          path: "/enter_email",
          name: "enter_email",
          component: () => import("@/Pages/AuthenticationPages/Background.vue"),
          props: true,
        },

        //ResetPassword.vue
        {
          path: "/Forgot-Password",
          name: "ResetPassword",
          component: () => import("@/Pages/AuthenticationPages/Background.vue"),
          props: true,
        },

        //SignUp.vue
        {
          path: "/Creating-Account",
          name: "Create Account",
          component: () => import("@/Pages/AuthenticationPages/Background.vue"),
        },

        //ThankYou.vue
        {
          path: "/result",
          name: "Thank_you",
          component: () => import("@/Pages/AuthenticationPages/Background.vue"),
        },
      ],
    },

    // =============================================================================
    //Layout with Navigations Bars
    // =============================================================================
    {
      path: "",
      component: () => import("@/components/DashboardLayout.vue"),
      children: [
        {
          path: "/",
          redirect: "/dashboard",
        },

        // =============================================================================
        //dashboard
        // =============================================================================
        {
          path: "/dashboard",
          name: "Dashboard",
          component: () => import("@/components/Dashboard.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard", // crumb text
              },
            ],
          },
        },

        // =============================================================================
        //Case Law Search
        // =============================================================================
        {
          path: "/Case_Law_Search/Search_Result",
          name: "SearchResult",
          component: () => import("@/Pages/CaseSearch/SearchResult.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "SearchResult", // hyperlink
                text: "\u2002Case Law Search", // crumb text
              },
            ],
          },
        },
        {
          path: "/Case_Law_Search/Cases_jurisdiction/:id",
          name: "Case_Law_Search_Cases_jurisdiction",
          component: () => import("@/Pages/CaseSearch/JurisdictionList.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "SearchResult", // hyperlink
                text: "\u2002Case Law Search\u2002/", // crumb text
              },

              {
                name: "Case_Law_Search_Cases_jurisdiction", // hyperlink
                text: "\u2002Case Jurisdictions", // crumb text
              },
            ],
          },
        },
        {
          path: "/Case_Law_Search/Case_Summary/:id",
          name: "Case_Law_Search_CaseSummary",
          component: () => import("@/Pages/CaseSearch/CaseSummary.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "SearchResult", // hyperlink
                text: "\u2002Case Law Search\u2002/", // crumb text
              },
              {
                name: "Case_Law_Search_Cases_jurisdiction", // hyperlink
                text: "\u2002Case Jurisdictions\u2002/", // crumb text
              },
              {
                name: "Case_Law_Search_CaseSummary", // hyperlink
                text: "\u2002Case Summary", // crumb text
              },
            ],
          },
        },
        {
          path: "/Case_Law_Search/Case_Summary/Precedent_Report/:id",
          name: "CaseSearchPrecedentReport",
          component: () => import("@/Pages/CaseSearch/PrecedentReport.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "SearchResult", // hyperlink
                text: "\u2002Case Law Search\u2002/", // crumb text
              },
              {
                name: "Case_Law_Search_Cases_jurisdiction", // hyperlink
                text: "\u2002Case Jurisdictions\u2002/", // crumb text
              },
              {
                name: "Case_Law_Search_CaseSummary", // hyperlink
                text: "\u2002Case Summary\u2002/", // crumb text
              },
              {
                name: "CaseSearchPrecedentReport", // hyperlink
                text: "\u2002Precedent Report", // crumb text
              },
            ],
          },
        },
        {
          path: "/Case_Law_Search/Case_Summary/Precedent_Report/:jurisId/Full_Case/:id",
          name: "CaseSearchPrecedentSummary",
          component: () => import("@/Pages/CaseSearch/PrecedentSummary.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "SearchResult", // hyperlink
                text: "\u2002Case Law Search\u2002/", // crumb text
              },
              {
                name: "Case_Law_Search_Cases_jurisdiction", // hyperlink
                text: "\u2002Case Jurisdictions\u2002/", // crumb text
              },
              {
                name: "Case_Law_Search_CaseSummary", // hyperlink
                text: "\u2002Case Summary\u2002/", // crumb text
              },
              {
                name: "CaseSearchPrecedentReport", // hyperlink
                text: "\u2002Precedent Report\u2002/", // crumb text
              },
              {
                name: "CaseSearchPrecedentSummary", // hyperlink
                text: "\u2002Full Case", // crumb text
              },
            ],
          },
        },
        {
          path: "/Case_Law_Search/Case_Summary/Annotation/:id/:id2/:id3",
          name: "CaseSearchAnnotation",
          component: () => import("@/Pages/CaseSearch/AnnotationCase.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "SearchResult", // hyperlink
                text: "\u2002Case Law Search\u2002/", // crumb text
              },
              {
                name: "Case_Law_Search_Cases_jurisdiction", // hyperlink
                text: "\u2002Case Jurisdictions\u2002/", // crumb text
              },
              {
                name: "Case_Law_Search_CaseSummary", // hyperlink
                text: "\u2002Case Summary\u2002/", // crumb text
              },
              {
                name: "CaseSearchAnnotation", // hyperlink
                text: "\u2002Annotation Case", // crumb text
              },
            ],
          },
        },

        // =============================================================================
        //Legislation Search
        // =============================================================================
        {
          path: "/Legislation_Search/Search_Result",
          name: "LegisSearchResult",
          component: () => import("@/Pages/LegislationSearch/SearchResult.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegisSearchResult", // hyperlink
                text: "\u2002Legislation Search", // crumb text
              },
            ],
          },
        },
        {
          path: "/Legislation_Search/Legislation_Jurisdiction/:id",
          name: "Legislation_jurisdiction",
          component: () =>
            import("@/Pages/LegislationSearch/JurisdictionList.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegisSearchResult", // hyperlink
                text: "\u2002Legislation Search\u2002/", // crumb text
              },

              {
                name: "Legislation_jurisdiction", // hyperlink
                text: "\u2002Legislation Jurisdictions", // crumb text
              },
            ],
          },
        },
        {
          path: "/Legislation_Search/Legislation_Summary/:id",
          name: "Legislation_Summary",
          component: () =>
            import("@/Pages/LegislationSearch/LegislationSummary.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegisSearchResult", // hyperlink
                text: "\u2002Legislation Search\u2002/", // crumb text
              },
              {
                name: "Legislation_jurisdiction", // hyperlink
                text: "\u2002Legislation Jurisdictions\u2002/", // crumb text
              },
              {
                name: "Legislation_Summary", // hyperlink
                text: "\u2002Legislation Summary", // crumb text
              },
            ],
          },
        },
        // {
        //   // path: "/Case_Law_Search/Case_Summary/Precedent_Report:id",
        //   path: "/Case_Law_Search/Case_Summary/Precedent_Report",
        //   name: "CaseSearchPrecedentReport",
        //   component: () => import("@/Pages/CaseSearch/PrecedentReport.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "Dashboard", // hyperlink
        //         text: "Dashboard / ", // crumb text
        //       },
        //       {
        //         name: "SearchResult", // hyperlink
        //         text: " Case Law Search / ", // crumb text
        //       },
        //       {
        //         name: "Case_Law_Search_Cases_jurisdiction", // hyperlink
        //         text: " Case Jurisdictions /", // crumb text
        //       },
        //       {
        //         name: "Case_Law_Search_CaseSummary", // hyperlink
        //         text: " Case Summary / ", // crumb text
        //       },
        //       {
        //         name: "CaseSearchPrecedentReport", // hyperlink
        //         text: " Precedent Report", // crumb text
        //       },
        //     ],
        //   },
        // },

        // =============================================================================
        //Case Law Summarizer
        // =============================================================================
        {
          path: "/Case_Summarizer/Judgment_File",
          name: "JudgmentFile",
          component: () => import("@/Pages/CaseSummarizer/JudgmentFile.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "JudgmentFile", // hyperlink
                text: "\u2002Case Law Summarizer", // crumb text
              },
            ],
          },
        },
        {
          path: "/Case_Summarizer/Case_Summary/:id",
          name: "Case_Summarizer_CaseSummary",
          component: () => import("@/Pages/CaseSummarizer/CaseSummary.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "JudgmentFile", // hyperlink
                text: "\u2002Case Law Summarizer\u2002/", // crumb text
              },
              {
                name: "Case_Summarizer_CaseSummary", // hyperlink
                text: "\u2002Case Summary", // crumb text
              },
            ],
          },
        },

        // =============================================================================
        //Contract Review
        // =============================================================================
        {
          path: "/Contract_Review/Choose_Party",
          name: "ChooseParty",
          component: () => import("@/Pages/ContractReview/ChooseParty.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "ChooseParty", // hyperlink
                text: "\u2002Contracting Party", // crumb text
              },
            ],
          },
        },
        {
          path: "/Contract_Review/:id",
          name: "DiscloserPage",
          component: () =>
            import("@/Pages/ContractReview/ResultPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "ChooseParty", // hyperlink
                text: "\u2002Contracting Party\u2002/", // crumb text
              },
              {
                name: "DisclosurePage", // hyperlink
                text: "\u2002Contract Review", // crumb text
              },
            ],
          },
        },
        {
          path: "/Contract_Review/:id",
          name: "RecipientPage",
          component: () =>
            import("@/Pages/ContractReview/ResultPage.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "ChooseParty", // hyperlink
                text: "\u2002Contracting Party\u2002/", // crumb text
              },
              {
                name: "RecipientPage", // hyperlink
                text: "\u2002Contract Review", // crumb text
              },
            ],
          },
        },

        {
          path: "/Contract_Review/Contract_Document/:id",
          name: "ContractDocumentDiscloser",
          component: () =>
            import("@/Pages/ContractReview/ContractDocument.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "ChooseParty", // hyperlink
                text: "\u2002Contracting Party\u2002/", // crumb text
              },
              {
                name: "RecipientPage", // hyperlink
                text: "\u2002Contract Review\u2002/", // crumb text
              },
              {
                name: "ContractDocumentDiscloser", // hyperlink
                text: "\u2002Contract Document", // crumb text
              },
            ],
          },
        },

        {
          path: "/Contract_Review/Contract_Document/:id",
          name: "ContractDocumentRecipient",
          component: () =>
            import("@/Pages/ContractReview/ContractDocument.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "ChooseParty", // hyperlink
                text: "\u2002Contracting Party\u2002/", // crumb text
              },
              {
                name: "RecipientPage", // hyperlink
                text: "\u2002Contract Review\u2002/", // crumb text
              },
              {
                name: "ContractDocument", // hyperlink
                text: "\u2002Contract Document", // crumb text
              },
            ],
          },
        },

        // {
        //   path: "/Contract_Review/Contract_editor/:id",
        //   name: "ContractEditor",
        //   component: () => import("@/Pages/ContractReview/EditorPage.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "Dashboard", // hyperlink
        //         text: "Dashboard\u2002/", // crumb text
        //       },
        //       {
        //         name: "ChooseParty", // hyperlink
        //         text: "\u2002Contracting Party\u2002/", // crumb text
        //       },
        //       {
        //         name: "RecipientPage", // hyperlink
        //         text: "\u2002Recipient Contract Review\u2002/", // crumb text
        //       },
        //       {
        //         name: "ContractDocument", // hyperlink
        //         text: "\u2002Contract Document\u2002/", // crumb text
        //       },
        //       {
        //         name: "ContractDocument", // hyperlink
        //         text: "\u2002Contract Document", // crumb text
        //       },
        //     ],
        //   },
        // },

        // =============================================================================
        //Legal AI Trial Copilot
        // =============================================================================
        //Karthikha
        {
          path: "/Legal_Copilot",
          name: "LegalExpertSystem",
          component: () => import("@/Pages/LegalExpertSystem/History.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystem", // hyperlink
                text: "\u2002Legal AI Trial Copilot", // crumb text
              },
            ],
          },
        },

        //Reena
        {
          path: "/Legal_Copilot/PreTrial_Input",
          name: "LegalExpertSystemInput",
          component: () =>
            import("@/Pages/LegalExpertSystem/LegalExpertInput.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystem", // hyperlink
                text: "\u2002Legal AI Trial Copilot\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystemInput", // hyperlink
                text: "\u2002Pre-Trial Input", // crumb text
              },
            ],
          },
        },
         {
          path: "/Legal_Copilot/Final_Input",
          name: "LegalExpertSystemFinalInput",
          component: () =>
            import("@/Pages/LegalExpertSystem/LegalExpertFinalInput.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystem", // hyperlink
                text: "\u2002Legal AI Trial Copilot\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystemFinalInput", // hyperlink
                text: "\u2002Final Submission", // crumb text
              },
            ],
          },
        },
        {
          path: "/Legal_Copilot/Trial_Input/:id",
          name: "LegalExpertSystemFileInput",
          component: () => import("@/Pages/LegalExpertSystem/FileUpload.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystem", // hyperlink
                text: "\u2002Legal AI Trial Copilot\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystemPreTrialResult", // hyperlink
                text: "\u2002Case Flow Result\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystemFileInput", // hyperlink
                text: "\u2002Trial Input", // crumb text
              },
            ],
          },
        },

        //Karthikha
        {
          path: "/Legal_Copilot/Case_Flow_Result/:id?/:category",
          name: "LegalExpertSystemPreTrialResult",
          component: () =>
            import("@/Pages/LegalExpertSystem/PreTrialResult.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystem", // hyperlink
                text: "\u2002Legal AI Trial Copilot\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystemPreTrialResult", // hyperlink
                text: "\u2002Case Flow Result", // crumb text
              },
            ],
          },
        },

        {
          path: "/Legal_Copilot/Case_Flow_Result/Document_View/:id?/:file/:category",
          name: "PreTrialDocView",
          component: () => import("@/Pages/LegalExpertSystem/DocumentView.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystem", // hyperlink
                text: "\u2002Legal AI Trial Copilot\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystemPreTrialResult", // hyperlink
                text: "\u2002Case Flow Result\u2002/", // crumb text
              },
              {
                name: "PreTrialDocView", // hyperlink
                text: "\u2002Document View", // crumb text
              },
            ],
          },
        },

        {
          path: "/Legal_Copilot/Final_Submission/:id",
          name: "LegalExpertSystemEndCase",
          component: () => import("@/Pages/LegalExpertSystem/EndCase.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystem", // hyperlink
                text: "\u2002Legal AI Trial Copilot\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystemPreTrialResult", // hyperlink
                text: "\u2002Case Flow Result\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystemEndCase", // hyperlink
                text: "\u2002Final Submission", // crumb text
              },
            ],
          },
        },
        {
          path: "/Legal_Copilot/LegalExpertMode/:id",
          name: "LegalExpertMode",
          component: () => import("@/Pages/LegalExpertSystem/LawBotPlus.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystem", // hyperlink
                text: "\u2002Legal AI Trial Copilot\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystemPreTrialResult", // hyperlink
                text: "\u2002Case Flow Result\u2002/", // crumb text
              },
              {
                name: "LegalExpertMode", // hyperlink
                text: "\u2002Expert Mode", // crumb text
              },
            ],
          },
        },
        {
          path: "/Legal_Copilot/New_Trial/:id",
          name: "LegalExpertSystemNewTrial",
          component: () => import("@/Pages/LegalExpertSystem/NewTrial.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystem", // hyperlink
                text: "\u2002Legal AI Trial Copilot\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystemPreTrialResult", // hyperlink
                text: "\u2002Case Flow Result\u2002/", // crumb text
              },
              {
                name: "LegalExpertSystemNewTrial", // hyperlink
                text: "\u2002New Trial", // crumb text
              },
            ],
          },
        },

        // =============================================================================
        //Legal Research
        // =============================================================================
        {
          path: "/Legal_Argument",
          name: "LegalArgument",
          component: () => import("@/Pages/LegalResearch/LegalArgument.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", //hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalArgument", //hyperlink
                text: "\u2002Legal Argument", // crumb text
              },
            ],
          },
        },

        {
          path: "/Legal_Memo_Opinion",
          name: "LegalMemoOpinion",
          component: () =>
            import("@/Pages/LegalResearch/LegalMemoAndOpinion.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", //hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalMemoOpinion", //hyperlink
                text: "\u2002Legal Research Memo and Opinion", // crumb text
              },
            ],
          },
        },

        // ref
        // {
        //   path: "/Legal_Research",
        //   name: "LegalResearch",
        //   component: () => import("@/Pages/LegalResearch/ChooseCategory.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "Dashboard", // hyperlink
        //         text: "Dashboard\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalResearch", // hyperlink
        //         text: "\u2002Choose Category", // crumb text
        //       },
        //     ],
        //   },
        // },

        // dep
        // {
        //   path: "/Legal_Research/History",
        //   name: "LegalResearchHistory",
        //   component: () => import("@/Pages/LegalResearch/History.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "Dashboard", // hyperlink
        //         text: "Dashboard\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalResearch", // hyperlink
        //         text: "\u2002Choose Category\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalResearchHistory", // hyperlink
        //         text: "\u2002Legal Research History", // crumb text
        //       },
        //     ],
        //   },
        // },

        // dep
        // {
        //   path: "/Legal_Research/InputSteps/:step",
        //   name: "LegalResearchInputs",
        //   component: () => import("@/Pages/LegalResearch/InputSteps.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "Dashboard", // hyperlink
        //         text: "Dashboard\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalResearch", // hyperlink
        //         text: "\u2002Choose Category\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalResearchInputs", // hyperlink
        //         text: "\u2002Generate", // crumb text
        //       },
        //     ],
        //   },
        // },

        {
          path: "/LegalResearchAISuggestion/:id",
          name: "LegalResearchAISuggestion",
          component: () => import("@/Pages/LegalResearch/AISuggestion.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              // {
              //   name: "LegalResearch", // hyperlink
              //   text: "\u2002Choose Category\u2002/", // crumb text
              // },
              {
                name: "LegalResearchAISuggestion", // hyperlink
                text: "\u2002Legal Research", // crumb text
              },
            ],
          },
        },

        {
          path: "LegalResearchSummary/case/:id/:case_id",
          name: "LegalResearchSummaryCase",
          component: () => import("@/Pages/LegalResearch/SummaryCase.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              // {
              //   name: "LegalResearch", // hyperlink
              //   text: "\u2002Choose Category\u2002/", // crumb text
              // },
              {
                name: "LegalResearchAISuggestion", // hyperlink
                text: "\u2002Legal Research\u2002/", // crumb text
              },
              {
                name: "LegalResearchSummaryCase", // hyperlink
                text: "\u2002Legal Research Summary", // crumb text
              },
            ],
          },
        },

        {
          path: "LegalResearchSummary/doc/:id/:case_id",
          name: "LegalResearchSummaryDoc",
          component: () => import("@/Pages/LegalResearch/SummaryDocument.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              // {
              //   name: "LegalResearch", // hyperlink
              //   text: "\u2002Choose Category\u2002/", // crumb text
              // },
              {
                name: "LegalResearchAISuggestion", // hyperlink
                text: "\u2002Legal Research\u2002/", // crumb text
              },
              {
                name: "LegalResearchSummaryDoc", // hyperlink
                text: "\u2002Legal Research Summary", // crumb text
              },
            ],
          },
        },

        // =============================================================================
        //Legal Writer
        // =============================================================================
        {
          path: "/Legal_Writer",
          name: "LegalWriter",
          component: () => import("@/Pages/LegalWriter/ChooseCategory.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalWriter", // hyperlink
                text: "\u2002Choose Category", // crumb text
              },
            ],
          },
        },

        {
          path: "/Legal_Writer/History",
          name: "LegalWriterHistory",
          component: () => import("@/Pages/LegalWriter/History.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalWriter", // hyperlink
                text: "\u2002Choose Category\u2002/", // crumb text
              },
              {
                name: "LegalWriterHistory", // hyperlink
                text: "\u2002Legal Writer History", // crumb text
              },
            ],
          },
        },

        // {
        //   path: "/Legal_Writer/AgreementCategory",
        //   name: "AgreementCategory",
        //   component: () => import("@/Pages/LegalWriter/AgreementCategory.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "Dashboard", // hyperlink
        //         text: "Dashboard\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriter", // hyperlink
        //         text: "\u2002Choose Category\u2002/", // crumb text
        //       },
        //       {
        //         name: "AgreementCategory", // hyperlink
        //         text: "\u2002Choose Agreement Category", // crumb text
        //       },
        //     ],
        //   },
        // },

        {
          path: "/Legal_Writer/InputSteps/:step",
          name: "LegalWriterInputs",
          component: () => import("@/Pages/LegalWriter/InputSteps.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalWriter", // hyperlink
                text: "\u2002Choose Category\u2002/", // crumb text
              },
              {
                name: "LegalWriterInputs", // hyperlink
                text: "\u2002Generate", // crumb text
              },
            ],
          },
        },

        {
          path: "/Legal_Writer/LegalWriterEdit/:id",
          name: "LegalWriterEdit",
          component: () => import("@/Pages/LegalWriter/EditLegalWriter.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "LegalWriter", // hyperlink
                text: "\u2002Choose Category\u2002/", // crumb text
              },
              {
                name: "LegalWriterInputs", // hyperlink
                text: "\u2002Legal Writer", // crumb text
              },
            ],
          },
        },

        // {
        //   path: "/Legal_Writer/LegalWriterAISuggestion/:id",
        //   name: "LegalWriterAISuggestion",
        //   component: () => import("@/Pages/LegalWriter/AISuggestion.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "Dashboard", // hyperlink
        //         text: "Dashboard\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriter", // hyperlink
        //         text: "\u2002Choose Category\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriterAISuggestion", // hyperlink
        //         text: "\u2002Legal Writer", // crumb text
        //       },
        //     ],
        //   },
        // },

        // {
        //   path: "/Legal_Writer/editor/:id",
        //   name: "LegalWriterEditor",
        //   component: () => import("@/Pages/LegalWriter/EditorPage.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "Dashboard", // hyperlink
        //         text: "Dashboard\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriter", // hyperlink
        //         text: "Choose Category\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriterAISuggestion", // hyperlink
        //         text: "\u2002Legal Writer\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriterEditor", // hyperlink
        //         text: "\u2002Editor", // crumb text
        //       },
        //     ],
        //   },
        // },

        // {
        //   path: "/Legal_Writer/LegalWriterSummary/case/:id/:case_id",
        //   name: "LegalWriterSummaryCase",
        //   component: () => import("@/Pages/LegalWriter/SummaryCase.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "Dashboard", // hyperlink
        //         text: "Dashboard\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriter", // hyperlink
        //         text: "\u2002Choose Category\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriterAISuggestion", // hyperlink
        //         text: "\u2002Legal Writer\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriterSummaryCase", // hyperlink
        //         text: "\u2002Legal Writer Summary", // crumb text
        //       },
        //     ],
        //   },
        // },

        // {
        //   path: "/Legal_Writer/LegalWriterSummary/doc/:id/:case_id",
        //   name: "LegalWriterSummaryDoc",
        //   component: () => import("@/Pages/LegalWriter/SummaryDocument.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "Dashboard", // hyperlink
        //         text: "Dashboard\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriter", // hyperlink
        //         text: "\u2002Choose Category\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriterAISuggestion", // hyperlink
        //         text: "\u2002Legal Writer\u2002/", // crumb text
        //       },
        //       {
        //         name: "LegalWriterSummaryDoc", // hyperlink
        //         text: "\u2002Legal Writer Summary", // crumb text
        //       },
        //     ],
        //   },
        // },

        // =============================================================================
        //Settings
        // =============================================================================
        {
          path: "/settings/profile",
          name: "profile",
          component: () => import("@/Pages/Settings/profile.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "profile", // hyperlink
                text: "\u2002Profile", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/billing_details",
          name: "billing_details",
          component: () => import("@/Pages/Settings/BillingDetails"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "billing_details", // hyperlink
                text: "\u2002Billing Details", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/employees",
          name: "Employees",
          component: () => import("@/Pages/Settings/employeesTable.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Employees", // hyperlink
                text: "\u2002Employees", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/clients",
          name: "Clients",
          component: () => import("@/Pages/Settings/ClientsTable.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Clients", // hyperlink
                text: "\u2002Clients", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/clients/client/:id",
          name: "client",
          component: () => import("@/Pages/Settings/ClientEdit.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Clients", // hyperlink
                text: "\u2002Clients\u2002/", // crumb text
              },
              {
                name: "client", // hyperlink
                text: "\u2002Client", // crumb text
              },
            ],
          },
        },

        {
          path: "/settings/clients/add_client",
          name: "add_client",
          component: () => import("@/Pages/Settings/AddClient.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Clients", // hyperlink
                text: "\u2002Clients\u2002/", // crumb text
              },
              {
                name: "add_client", // hyperlink
                text: "\u2002Add Client", // crumb text
              },
            ],
          },
        },

        {
          path: "/settings/roles",
          name: "Roles",
          component: () => import("@/Pages/Settings/rolesTable.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Roles", // hyperlink
                text: "\u2002Roles", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/policies",
          name: "Policies",
          component: () => import("@/Pages/Settings/policiesTable.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Policies", // hyperlink
                text: "\u2002Policies", // crumb text
              },
            ],
          },
        },

        {
          path: "/settings/employees/employee",
          name: "employee",
          component: () => import("@/Pages/Settings/EmployeEdit.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Employees", // hyperlink
                text: "\u2002Employees\u2002/", // crumb text
              },
              {
                name: "employee", // hyperlink
                text: "\u2002Employee", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/roles/role",
          name: "role",
          component: () => import("@/Pages/Settings/RoleEdit.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Roles", // hyperlink
                text: "\u2002Roles\u2002/", // crumb text
              },
              {
                name: "role", // hyperlink
                text: "\u2002Role", // crumb text
              },
            ],
          },
        },
        {
          path: "/settings/policies/policy",
          name: "policy",
          component: () => import("@/Pages/Settings/PolicyEdit.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Policies", // hyperlink
                text: "\u2002Policies\u2002/", // crumb text
              },
              {
                name: "policy", // hyperlink
                text: "\u2002Policy", // crumb text
              },
            ],
          },
        },

        // =============================================================================
        //Tutorial
        // =============================================================================
        {
          path: "/tutorial",
          name: "tutorial",
          component: () => import("@/components/Tutorial.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "tutorial", // hyperlink
                text: "\u2002Tutorial", // crumb text
              },
            ],
          },
        },

        // =============================================================================
        //Testing
        // =============================================================================
        {
          path: "/Testing",
          name: "Testing",
          component: () => import("@/Pages/chartTesting.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Testing", // hyperlink
                text: "Testing\u2002", // crumb text
              },
            ],
          },
        },
        {
          path: "/TestingTreeChart",
          name: "TestingTreeChart",
          component: () => import("@/Pages/testingTreeChart.vue"),
          meta: {
            breadCrumb: [
              {
                name: "TreeChart", // hyperlink
                text: "TreeChart ", // crumb text
              },
            ],
          },
        },
      ],
    },

    //No-Plan Layouts

    {
      path: "",
      component: () => import("@/components/DashboardLayout.vue"),
      children: [
        {
          path: "/",
          redirect: "/dashboard",
        },

        // =============================================================================
        //dashboard
        // =============================================================================
        // {
        //   path: "/dashboard",
        //   name: "PricingDashboard",
        //   component: () => import("@/Pages/NoPlanPages/Dashboard.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "Dashboard", // hyperlink
        //         text: "Dashboard", // crumb text
        //       },
        //     ],
        //   },
        // },

        // {
        //   path: "/pricing",
        //   name: "Pricing",
        //   component: () => import("@/Pages/NoPlanPages/Pricing.vue"),
        //   meta: {
        //     breadCrumb: [
        //       {
        //         name: "PricingDashboard", // hyperlink
        //         text: "Dashboard\u2002/", // crumb text
        //       },
        //       {
        //         name: "Pricing", // hyperlink
        //         text: "\u2002Pricing", // crumb text
        //       },
        //     ],
        //   },
        // },

        // =============================================================================
        //Subscribe Page
        // =============================================================================
        {
          path: "/pricing_subscribe/:plan_id/:plan_price_id/:period/:price",
          name: "pricing_subscribe",
          component: () => import("@/Pages/NoPlanPages/Subscribe.vue"),
          meta: {
            breadCrumb: [
              {
                name: "Dashboard", // hyperlink
                text: "Dashboard\u2002/", // crumb text
              },
              {
                name: "Pricing", // hyperlink
                text: "\u2002Pricing\u2002/", // crumb text
              },
              {
                name: "pricing_subscribe", // hyperlink
                text: "\u2002Subscribe", // crumb text
              },
            ],
          },
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (
    from.name === "LegalExpertSystemPreTrialResult" &&
    from.params.category === "Trial" &&
    to.name === "LegalExpertSystemFileInput"
  ) {
    next("/Legal_Copilot");
  } else {
    next();
  }
});

export default router;
